import React, { Fragment, useState, useEffect } from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import IndexTab from "../zenix/Home/IndexTab";
import PageTitle from "../../layouts/PageTitle";

import {
  getIsSubmitted,
  getIsApproved,
  getRoleName,
  getPilotCity,
} from "../../../store/selectors/AuthSelectors";

import { useDispatch, useSelector } from "react-redux";
import {
  getDashboard,
  getDashboardMarket,
  getMarketDataLoadingStatus,
  getTopMarketCoins,
} from "../../../store/selectors/DashboardSelector";
import { getUserId } from "../../../store/selectors/AuthSelectors";
import {
  dashboardList,
  listDashboardMarket,
} from "../../../store/actions/DashboardAction";

//Images
import coinImage from "./../../../images/card/card4.jpg";
import SNFT from "../../../images/svg/Nft.svg";
import SDonation from "../../../images/svg/donation.svg";
import SContest from "../../../images/svg/contest.svg";
import SReward from "../../../images/svg/reward.svg";
import SLogiEW from "../../../images/svg/LogiETokenWhite.svg";
import Loading from "../partials/Loading";
import { nFormatter } from "../../../api/constants";

const MarketOverviewChart = loadable(() =>
  pMinDelay(import("../zenix/Home/MarketOverviewChart"), 1000)
);
const CurrentChart = loadable(() =>
  pMinDelay(import("../zenix/Home/CurrentChart"), 1000)
);

const orderdataBlog = [
  //   { price: "82.1", amount: "58.9", total: "134.10" },
  //   { price: "85.2", amount: "55.8", total: "136,12" },
  //   { price: "87.3", amount: "53.7", total: "138,12" },
  //   { price: "89.4", amount: "51.6", total: "139,12" },
  //   { price: "91.9", amount: "47.1", total: "140,12" },
  //   { price: "93.8", amount: "46.2", total: "142,12" },
  //   { price: "94.7", amount: "45.3", total: "145,12" },
  //   { price: "97.6", amount: "44.4", total: "147,12" },
];

const Home = () => {
  const dispatch = useDispatch();
  const data = useSelector(getDashboard);
  const userId = useSelector(getUserId);
  // const { changeBackground } = useContext(ThemeContext);
  const roleName = useSelector(getRoleName);
  const isApproved = useSelector(getIsApproved);
  const isSubmitted = useSelector(getIsSubmitted);
  const marketCap = useSelector(getDashboardMarket);
  const pilotCity = useSelector(getPilotCity);
  const marketCoins = useSelector(getTopMarketCoins);
  const isMarketDataLoading = useSelector(getMarketDataLoadingStatus);
  const [tempData, setTempdata] = useState("");

  useEffect(() => {
    dispatch(dashboardList(userId));
    if (marketCap.length == 0) {
      dispatch(listDashboardMarket(userId));
    }
  }, []);
  useEffect(() => {
    if (pilotCity) {
      const fetchApi = async () => {
        let url = `https://api.openweathermap.org/data/2.5/weather?q=${pilotCity.name}&appid=eeb417f54966d47927dcf7b75abea486`;
        const response = await fetch(url);
        const data = await response.json();
        const tempdata = data.main;
        setTempdata(tempdata);
      };
      fetchApi();
    }
  }, []);

  return (
    <Fragment>
      {isSubmitted && !isApproved ? (
        <PageTitle
          pageContent="Your account is in review"
          onlyContent={true}
          contentColor={"#27AE60"}
        />
      ) : null}
      <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
        <h2 className="font-w600 mb-2 mr-auto ">
          {isSubmitted ? "Dashboard" : "Account Info"}
        </h2>
        <div className="weather-btn mb-2">
          <span className="mr-3 fs-16 font-w600 text-black">
            <i className="fa fa-cloud mr-2"></i>
            {tempData ? Math.round(tempData.temp - 273.15) : null}
            <span> &#176;C</span>
          </span>
          {pilotCity.name + ", " + pilotCity.country_code.toUpperCase()}
        </div>
        {/* <Link to={"#"} className="btn btn-secondary text-white mb-2"><i className="las la-calendar scale5 mr-3"></i>Filter Periode</Link> */}
      </div>
      <div className="row">
        <div className="col-xl-3 col-sm-6 m-t35">
          <div className="card card-coin">
            <div className="card-body text-center">
              <img
                src={SDonation}
                className="mb-3 currency-icon"
                width="80"
                height="80"
              />
              <h2 className="text-black mb-2 font-w600">
                {data.plantationCount}
              </h2>
              <p className="mb-0 fs-14">Plantation</p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 m-t35">
          <div className="card card-coin">
            <div className="card-body text-center">
              <img
                src={SContest}
                className="mb-3 currency-icon"
                width="80"
                height="80"
              />
              <h2 className="text-black mb-2 font-w600">{data.contestCount}</h2>
              <p className="mb-0 fs-13">Contests</p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 m-t35">
          <div className="card card-coin">
            <div className="card-body text-center">
              <img
                src={SReward}
                className="mb-3 currency-icon"
                width="80"
                height="80"
              />
              <h2 className="text-black mb-2 font-w600">{data.rewardCount}</h2>
              <p className="mb-0 fs-14">Rewards</p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 m-t35">
          <div className="card card-coin">
            <div className="card-body text-center">
              <img
                src={SNFT}
                className="mb-3 currency-icon"
                width="80"
                height="80"
              />
              <h2 className="text-black mb-2 font-w600">{data.nftCount}</h2>
              <p className="mb-0 fs-14">NFTs You Owe</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* <QuickTrade />
        <QuickTransfer /> */}
        <div
          className={`${
            roleName == "SuperAdmin"
              ? "col-xl-9 col-xxl-8"
              : "col-xl-12 col-xxl-12"
          }`}
        >
          {isMarketDataLoading ? (
            <div className="card">
              <div className="card-body pb-0">
                <Loading />
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-header border-0 flex-wrap pb-0 select-btn">
                <div className="mb-3">
                  <h4 className="fs-20 text-black">Market Overview</h4>
                  <p className="mb-0 fs-12 text-black">
                    A brief synopsis of a commercial or industrial market
                  </p>
                </div>
              </div>
              <div className="card-body pb-0">
                <MarketOverviewChart
                  {...{
                    names: marketCoins ? marketCoins.names : [],
                    prices: marketCoins ? marketCoins.prices : [],
                    currency: pilotCity.currency_code,
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {roleName == "SuperAdmin" ? (
          <div className="col-xl-3 col-xxl-4">
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h4 className="fs-20 text-black">Current Statistic</h4>
              </div>
              <div className="pb-0">
                <div id="currentChart" className="current-chart">
                  <CurrentChart
                    series={
                      data.userCount
                        ? [
                            data.userCount.active,
                            data.userCount.inactive,
                            data.userCount.businesses,
                            data.userCount.individual,
                          ]
                        : [0, 0, 0, 0]
                    }
                  />
                </div>
                <div className="card-body">
                  <div className="chart-content">
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div>
                        <svg
                          className="mr-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#71B945"
                          />
                        </svg>

                        <span className="fs-14">Active Users</span>
                      </div>
                      <div>
                        {data.userCount ? (
                          <h5 className="mb-0">{data.userCount.active}</h5>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div>
                        <svg
                          className="mr-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#558b2f"
                          />
                        </svg>
                        <span className="fs-14">Inactive Users</span>
                      </div>
                      <div>
                        {data.userCount ? (
                          <h5 className="mb-0">{data.userCount.inactive}</h5>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div>
                        <svg
                          className="mr-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#4A8CDA"
                          />
                        </svg>
                        <span className="fs-14">Organization</span>
                      </div>
                      <div>
                        {data.userCount ? (
                          <h5 className="mb-0">{data.userCount.businesses}</h5>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-2 align-items-center">
                      <div>
                        <svg
                          className="mr-2"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="15"
                            height="15"
                            rx="7.5"
                            fill="#6647BF"
                          />
                        </svg>
                        <span className="fs-14">Individuals</span>
                      </div>
                      <div>
                        {data.userCount ? (
                          <h5 className="mb-0">{data.userCount.individual}</h5>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="row">
        <div className="col-xl-12">
          <IndexTab />
        </div>
        <div className="col-xl-6 col-xxl-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card-bx stacked card">
                <img src={coinImage} alt="" />
                <div className="card-info">
                  <p className="mb-1 text-white fs-14">LogiE Token</p>
                  <div className="d-flex justify-content-between">
                    <h2 className="num-text text-white mb-5 font-w600">
                      {nFormatter(data.accountTokenBalance)}
                    </h2>
                    <img
                      src={SLogiEW}
                      className="pull-right"
                      style={{
                        width: 72,
                        height: 72,
                        position: "absolute",
                        top: 20,
                        right: 20,
                      }}
                    />
                  </div>
                  <div className="d-flex">
                    <div className="mr-4 text-white">
                      <p className="fs-12 mb-1 op6">Change</p>
                      <span>0%</span>
                    </div>
                    <div className="text-white">
                      <p className="fs-12 mb-1 op6">Total Supply</p>
                      <span>{nFormatter(data.totalSupplyOfToken)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export { orderdataBlog };
export default Home;
