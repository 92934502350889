import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import { reduxForm } from "redux-form";
import { updateProfile } from "../../../../../services/AuthService";
import {
  getUserId,
  getBearerToken,
  getUser,
  getIsSubmitted,
} from "../../../../../store/selectors/AuthSelectors";
import { getCities } from "../../../../../services/AuthService";
import Formdata from "form-data";
import { roleList } from "../../../../../store/actions/RoleActions";
import { getRoles } from "../../../../../store/selectors/RoleSelector";
import { useSelector, useDispatch, connect } from "react-redux";
import { getCountries } from "../../../../../store/selectors/CountrySelector";
import { listCountries } from "../../../../../store/actions/CountryAction";
import { matchByEmail } from "../../../../../services/UserServices";
import { toast } from "react-toastify";
import Loader from "../../../../loader.json";
import Lottie from "lottie-react";
import { Stepper } from "react-form-stepper";
import { submitAccountStep1 } from "../../../../../api/validator";
import Webcam from "react-webcam";
import { SlPicture } from "react-icons/sl";
import { dataToBlob } from "../../../../../api/constants";
import Modal from "../../../partials/Modal";
import { getKycList } from "../../../../../store/selectors/KycSelectors";
import { getKycRequiredDocsAction } from "../../../../../store/actions/KycActions";
import {
  uploadKycDoc,
  verifyKycDocs,
} from "../../../../../services/KycService";
import { useHistory } from "react-router-dom";
import GooglePlayStoreImage from "../../../../../images/google_play_store.png";
import AppleAppStoreImage from "../../../../../images/apple_app_store.png";
import { loginConfirmedAction } from "../../../../../store/actions/AuthActions";

const Wizard = (props) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    roleId: 3,
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone_code: "",
    phone: "",
    dateOfBirth: "",
    town_area: "",
    tagLine: "",
    avatar: null,
    cover: null,
    registration: "",
    certificate: null,
    isNGO: false,
    company_name: "",
    license_id: null,
    selfie: null,
    proof_of_address: null,
  });

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userId = useSelector(getUserId);
  const bearerToken = useSelector(getBearerToken);
  const isSubmitted = useSelector(getIsSubmitted);
  const roles = useSelector(getRoles);
  const countries = useSelector(getCountries);
  const [errors, setErrors] = useState({});
  const [showloader, setShowLoader] = useState(false);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);
  const [kycDocsData, setKycDocsData] = useState([]);
  const [isDataReady, setIsDataReady] = useState([]);
  const [authData, setAuthData] = useState(null);
  const webcam = useRef();
  const kycData = useSelector(getKycList);
  const history = useHistory();

  useEffect(() => {
    getKycRequiredDocs();
  }, []);

  useEffect(() => {
    if (kycData && kycData.length > 0 && !isDataReady) {
      setKycDocsData(
        kycData.map((doc) => ({ ...doc, percentage: doc.uploaded ? 100 : 0 }))
      );
      setIsDataReady(true);
    }
  }, [kycData]);

  function getKycRequiredDocs() {
    dispatch(getKycRequiredDocsAction(userId, {}));
  }

  const defaultSelfie = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };
  const [selfieProps, setSelfieProps] = useState(defaultSelfie);

  useEffect(() => {
    dispatch(roleList());
    dispatch(listCountries());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      let _data = {};
      for (const key in data) {
        _data = { ..._data, [key]: user[key] };
      }
      setData((pre) => ({
        ...pre,
        ..._data,
      }));
    }
  }, [user]);

  useEffect(() => {
    getCitiesData();
  }, []);

  function getCitiesData() {
    getCities()
      .then((res) => {
        if (res.data.status == 200) {
          setCities(res.data.cities);
          const londonCity = res.data.cities.find((_city) => _city.id == 4);
          if (londonCity) {
            setCity(londonCity);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleChangeCity(e) {
    const cityId = parseInt(e.target.value);
    const _city = cities.find((citi) => citi.id == cityId);
    if (_city) {
      setData((prevData) => ({
        ...prevData,
        phone_code: _city.phone_code,
      }));
      setCity(_city);
    } else {
      setCity(null);
    }
  }

  function handleFormErrors(key, value) {
    setErrors((err) => ({ ...err, [key]: value }));
  }

  const handleNext = () => {
    if (step == 0) {
      const isValid = submitAccountStep1(data);
      if (isValid !== true) {
        setErrors(isValid);
        return;
      }
      onSubmit();
    } else if (step == 1) {
      if (user.kyc_flag == "P") {
        submitKycDocs();
      } else {
        setStep((prevStep) => prevStep + 1);
      }
    }
  };

  function submitKycDocs() {
    verifyKycDocs(userId)
      .then((res) => {
        if (res.data.status == 200) {
          toast.success("KYC documents successfully submitted");
          getKycRequiredDocs();
          setStep((prevStep) => prevStep + 1);
        } else {
          toast.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  const handlePrev = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (key, e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (key === "roleId") {
      setData((prevData) => ({
        ...prevData,
        isNGO: false,
        [key]: value,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };

  function handleChangeFile(key, e) {
    if (e && typeof e === "string") {
      setData((prevState) => ({
        ...prevState,
        [key]: e,
      }));
    } else {
      const file = e.target.files[0];
      if (file) {
        setData((prevState) => ({
          ...prevState,
          [key]: file,
        }));
      }
    }
  }

  async function onSubmit() {
    try {
      const formdata = new Formdata();
      for (const key in data) {
        if (key === "selfie" && data[key]) {
          const blob = dataToBlob(data[key]);
          formdata.append(key, blob);
        } else if (key == "username") {
          formdata.append(key, data.email);
        } else if (key == "phone") {
          formdata.append(key, data.phone_code + data.phone);
        } else {
          formdata.append(key, data[key]);
        }
      }
      formdata.append("cityId", city.id);
      formdata.append("status", "Pending Review");
      setShowLoader(true);
      updateProfile(userId, formdata, { Authorization: bearerToken })
        .then((res) => {
          if (res.data.status == 200) {
            toast.success("Good Job! Account info successfully submitted.");
            setShowLoader(false);
            getKycRequiredDocs();
            setStep((prevStep) => prevStep + 1);
            setAuthData(res.data);
            // dispatch(loginConfirmedAction(res.data));
            // history.push("dashboard");
          } else {
            toast.warning("Oops! Something went wrong");
            setShowLoader(false);
          }
        })
        .catch((err) => {
          toast.warning("Oops! Something went wrong");
          setShowLoader(false);
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <Fragment>
      <PageTitle
        pageContent={
          isSubmitted
            ? "Your profile information is currently in review!"
            : "Complete your profile to get your account approved!"
        }
        activeMenu="Account"
        motherMenu="Dashboard"
      />
      <div className="row">
        <div className="col-xl-5 col-xxl-5">
          <Stepper
            steps={[
              { label: "Your Profile Info" },
              {
                label: Number(data.roleId) === 2 ? "Company Info" : "KYC Docs",
              },
              {
                label: "Wallet",
              },
            ]}
            activeStep={step}
            styleConfig={{
              activeBgColor: "#558B2F",
              activeTextColor: "white",
              completedBgColor: "#3B6021",
              completedTextColor: "white",
            }}
          />
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {step == 0
                  ? "Your Profile Info"
                  : step == 1 && Number(data.roleId) === 2
                  ? "Company Info"
                  : "Your Identification"}
              </h4>
            </div>
            <div className="card-body">
              <div className="form-wizard ">
                <section>
                  <div className="row">
                    {/* Other fields based on user role and step */}
                    {step == 0 ? (
                      <Fragment>
                        {Number(data.roleId) === 2 ? (
                          <div className="col-lg-12 mb-2">
                            <h4>Representative Info</h4>
                          </div>
                        ) : null}
                        <div className="col-lg-6 mb-2">
                          <label className="text-label">First Name *</label>
                          <input
                            type="text"
                            value={data.first_name || ""}
                            name="first_name"
                            className="form-control"
                            onChange={(e) => {
                              handleChange("first_name", e);
                              handleFormErrors("first_name", "");
                            }}
                            disabled={isSubmitted}
                            required
                          />
                          <small className="text-danger">
                            {errors.first_name}
                          </small>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label className="text-label">Last Name *</label>
                          <input
                            type="text"
                            value={data.last_name || ""}
                            name="last_name"
                            className="form-control"
                            onChange={(e) => {
                              handleChange("last_name", e);
                              handleFormErrors("last_name", "");
                            }}
                            disabled={isSubmitted}
                            required
                          />
                          <small className="text-danger">
                            {errors.last_name}
                          </small>
                        </div>
                        <div className="col-lg-12 mb-2">
                          <label className="text-label">Email Address*</label>
                          <input
                            type="email"
                            name="email"
                            value={data.email || ""}
                            className="form-control"
                            onChange={(e) => handleChange("email", e)}
                            disabled={isSubmitted || data.reg_flag == "E"}
                            required
                            onBlur={() => {
                              matchByEmail(data.email)
                                .then((response) => {
                                  if (response.data.status === 302) {
                                    handleFormErrors(
                                      "email",
                                      response.data.message
                                    );
                                  } else {
                                    handleFormErrors("email", "");
                                  }
                                })
                                .catch((error) => {
                                  handleFormErrors("email", "");
                                });
                            }}
                          />
                          {errors.email ? (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          ) : null}
                        </div>

                        <div className="col-lg-12 mb-2">
                          <label className="text-label">Select Country*</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <span
                                  className={
                                    city
                                      ? "fi fi-" + city.country_code
                                      : "fi fi-un"
                                  }
                                ></span>
                              </span>
                            </div>
                            <select
                              defaultValue={""}
                              className="form-control custom-select"
                              onChange={handleChangeCity}
                            >
                              <option value={""}>Select Country</option>
                              {cities
                                ? cities.map((city, i) => (
                                    <option key={i} value={city.id}>
                                      {city.country}
                                    </option>
                                  ))
                                : null}
                            </select>
                          </div>
                          {errors.phone_code ? (
                            <small className="text-danger">
                              {errors.phone_code}
                            </small>
                          ) : null}
                        </div>
                        <div className="col-lg-12 mb-2">
                          <label className="">
                            <strong>Phone Number</strong>
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                {city ? city.phone_code : ""}
                              </span>
                            </div>
                            <input
                              value={data.phone}
                              onChange={(e) => handleChange("phone", e)}
                              className="form-control"
                              placeholder={city ? city.phone_pattern : ""}
                              type="number"
                              max={city ? city.max_phone_digits : 0}
                              maxLength={city ? city.max_phone_digits : 0}
                              disabled={city == null}
                            />
                          </div>
                          {errors.phone ? (
                            <small className="text-danger">
                              {errors.phone}
                            </small>
                          ) : null}
                        </div>
                        <div className="col-lg-12 mb-2">
                          <label className="text-label">Date of birth</label>
                          <input
                            type="date"
                            value={data.dateOfBirth || ""}
                            name="dateOfBirth"
                            className="form-control"
                            onChange={(e) => handleChange("dateOfBirth", e)}
                            disabled={isSubmitted}
                          />
                          {errors.dateOfBirth ? (
                            <small className="text-danger">
                              {errors.dateOfBirth}
                            </small>
                          ) : null}
                        </div>

                        <div className="col-lg-12 mb-2">
                          <label className="text-label">City/Town/Area</label>
                          <input
                            type="text"
                            value={data.town_area || ""}
                            name="town_area"
                            className="form-control"
                            onChange={(e) => handleChange("town_area", e)}
                            disabled={isSubmitted}
                          />
                          {errors.town_area ? (
                            <small className="text-danger">
                              {errors.town_area}
                            </small>
                          ) : null}
                        </div>

                        {data.isNGO && (
                          <Fragment>
                            <div className="col-lg-12 mb-2">
                              <label className="text-label">Country*</label>
                              <select
                                className="form-control custom-select"
                                value={data.countryId || ""}
                                onChange={(e) => {
                                  handleChange("countryId", e);
                                  handleFormErrors("countryId", "");
                                }}
                                disabled={isSubmitted}
                                required
                              >
                                <option value={""}>Select</option>
                                {countries &&
                                  countries.map((country, i) => (
                                    <option
                                      key={i}
                                      value={country.id}
                                      selected={country.id == data.countryId}
                                    >
                                      {country.countryName}
                                    </option>
                                  ))}
                              </select>
                              {errors.countryId ? (
                                <small className="text-danger">
                                  {errors.countryId}
                                </small>
                              ) : null}
                            </div>
                          </Fragment>
                        )}
                        <div className="col-lg-12 mb-4">
                          <label className="text-label">Type</label>
                          <select
                            value={data.roleId || ""}
                            className="form-control custom-select"
                            onChange={(e) => {
                              handleChange("roleId", e);
                              handleFormErrors("roleId", "");
                            }}
                          >
                            <option value={""}>Select</option>
                            {roles
                              ? roles.map((role, i) => (
                                  <option
                                    key={i}
                                    value={role.id}
                                    selected={
                                      Number(data.roleId) == Number(role.id)
                                    }
                                  >
                                    {role.name}
                                  </option>
                                ))
                              : null}
                          </select>
                          <small className="text-danger">{errors.roleId}</small>
                        </div>
                      </Fragment>
                    ) : null}

                    {step === 1 && Number(data.roleId) === 2 ? (
                      <Fragment>
                        <div className="col-lg-12 mb-2">
                          <label className="text-label">Company Name*</label>
                          <input
                            type="text"
                            name="company_name"
                            className="form-control"
                            onChange={(e) => {
                              handleChange("company_name", e);
                              handleFormErrors("company_name", "");
                            }}
                            disabled={isSubmitted}
                            required
                          />
                          {errors.company_name ? (
                            <small className="text-danger">
                              {errors.company_name}
                            </small>
                          ) : null}
                        </div>
                      </Fragment>
                    ) : null}

                    {step === 0 && Number(data.roleId) === 2 ? (
                      <Fragment>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="text-label">
                              ID Card / Driving License / Passport*
                            </label>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="license_id"
                                className="custom-file-input"
                                onChange={(e) => {
                                  handleChangeFile("license_id", e);
                                  handleFormErrors("license_id", "");
                                }}
                                disabled={isSubmitted}
                                required
                              />
                              <label className="custom-file-label">
                                {data.license_id
                                  ? typeof data.license_id === "string"
                                    ? data.license_id
                                    : data.license_id.name
                                  : "Choose file"}
                              </label>
                            </div>
                            {errors.license_id ? (
                              <small className="text-danger">
                                {errors.license_id}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="text-label">
                              Proof of Address (e.g. Utility Bill, Bank
                              Statement, Council/Municipality tax bill)*
                            </label>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="proof_of_address"
                                className="custom-file-input"
                                onChange={(e) => {
                                  handleChangeFile("proof_of_address", e);
                                  handleFormErrors("proof_of_address", "");
                                }}
                                disabled={isSubmitted}
                                required
                              />
                              <label className="custom-file-label">
                                {data.proof_of_address
                                  ? typeof data.proof_of_address === "string"
                                    ? data.proof_of_address
                                    : data.proof_of_address.name
                                  : "Choose file"}
                              </label>
                            </div>
                            {errors.proof_of_address ? (
                              <small className="text-danger">
                                {errors.proof_of_address}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <label className="text-label">Selfie*</label>
                            <div
                              style={{
                                width: "100%",
                                height: 450,
                                border: "1px dashed",
                                padding: 10,
                                borderRadius: 10,
                                cursor: "pointer",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                display: "flex",
                                flex: 1,
                              }}
                              onClick={() =>
                                setSelfieProps((pre) => ({
                                  ...pre,
                                  open: true,
                                  title: "Selfie",
                                  noBtn: {
                                    title: "Dismiss",
                                    onTap: () => {
                                      setSelfieProps(defaultSelfie);
                                    },
                                  },
                                  yesBtn: {
                                    title: "Capture",
                                    onTap: () => {
                                      const imageSrc =
                                        webcam.current.getScreenshot({
                                          width: 1920,
                                          height: 1080,
                                        });

                                      if (imageSrc) {
                                        handleChangeFile("selfie", imageSrc);
                                        handleFormErrors("selfie", "");
                                      }
                                      setSelfieProps(defaultSelfie);
                                    },
                                  },
                                  onClose: () => {
                                    setSelfieProps(defaultSelfie);
                                  },
                                }))
                              }
                            >
                              {data.selfie ? (
                                <img
                                  src={data.selfie}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <SlPicture width={32} height={32} />
                                  <h4>Selfie</h4>
                                  <p>Capture an image of your face</p>
                                </div>
                              )}
                            </div>
                            {errors.selfie ? (
                              <small className="text-danger">
                                {errors.selfie}
                              </small>
                            ) : null}
                          </div>
                        </div>
                      </Fragment>
                    ) : null}
                    {step === 1 && Number(data.roleId) === 3 ? (
                      <Fragment>
                        {user.kyc_flag !== "P" ? (
                          <small class="text-center mb-3">
                            You've already submitted your kyc documents
                          </small>
                        ) : null}
                        {user.kyc_flag == "P" &&
                          kycData.map((doc, i) => (
                            <div key={i} className="col-lg-12 mb-2">
                              <label className="text-label">{doc.title}*</label>
                              <input
                                type="file"
                                name="registration"
                                className="form-control"
                                onChange={async (e) => {
                                  const file = e.target.files[0];
                                  const formdata = new Formdata();

                                  formdata.append("userId", userId);
                                  formdata.append("docType", doc.type);
                                  formdata.append("doc", file);
                                  uploadKycDoc(formdata, {
                                    Authorization: bearerToken,
                                  })
                                    .then((res) => {
                                      if (res.data.status == 200) {
                                      }
                                    })
                                    .catch((err) => {
                                      console.log("err", err);
                                    });
                                }}
                                required
                              />
                              {errors.registration ? (
                                <small className="text-danger">
                                  {errors.registration}
                                </small>
                              ) : null}
                            </div>
                          ))}
                      </Fragment>
                    ) : null}

                    {step === 1 && Number(data.roleId) === 2 ? (
                      <Fragment>
                        <div className="col-lg-12 mb-2">
                          <label className="text-label">Registration No*</label>
                          <input
                            type="text"
                            name="registration"
                            className="form-control"
                            onChange={(e) => {
                              handleChange("registration", e);
                              handleFormErrors("registration", "");
                            }}
                            disabled={isSubmitted}
                            required
                          />
                          {errors.registration ? (
                            <small className="text-danger">
                              {errors.registration}
                            </small>
                          ) : null}
                        </div>
                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <label className="text-label">
                              Registration Certificate*
                            </label>
                            <div className="custom-file">
                              <input
                                type="file"
                                name="certificate"
                                className="custom-file-input"
                                onChange={(e) => {
                                  handleChangeFile("certificate", e);
                                  handleFormErrors("certificate", "");
                                }}
                                disabled={isSubmitted}
                              />
                              <label className="custom-file-label">
                                {data.certificate
                                  ? typeof data.certificate === "string"
                                    ? data.certificate
                                    : data.certificate.name
                                  : "Choose file"}
                              </label>
                            </div>
                            {errors.certificate ? (
                              <small className="text-danger">
                                {errors.certificate}
                              </small>
                            ) : null}
                          </div>
                        </div>
                      </Fragment>
                    ) : null}

                    {step === 2 ? (
                      <Fragment>
                        <div className="col-lg-12 mb-2">
                          <h4>Download the TerraNova App</h4>
                          <p>
                            Download the TerraNova app now to attach a new
                            crypto wallet with your account.
                          </p>
                          <div className="d-flex justify-content-center">
                            <a
                              href="https://play.google.com/store/apps/details?id=com.yogya.terranovaapp"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={GooglePlayStoreImage}
                                alt="Google Play Store"
                                style={{ width: 150, marginRight: 10 }}
                              />
                            </a>
                            <a
                              href="https://apps.apple.com/us/app/terra-nova-logie/id6471402187"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={AppleAppStoreImage}
                                alt="Apple App Store"
                                style={{ width: 150 }}
                              />
                            </a>
                          </div>
                        </div>
                      </Fragment>
                    ) : null}
                  </div>
                </section>
                {/* Next and Previous buttons */}
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    {step > 0 && (
                      <button
                        className="btn btn-primary sw-btn-prev"
                        onClick={handlePrev}
                        type="button"
                      >
                        Previous
                      </button>
                    )}
                    {step < 2 ? (
                      <button
                        className="btn btn-primary sw-btn-next ml-1"
                        onClick={handleNext}
                        type="button"
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary sw-btn-next ml-1"
                        type="button"
                        onClick={() => {
                          if (authData) {
                            dispatch(loginConfirmedAction(authData));
                          }
                          history.push("dashboard");
                        }}
                      >
                        Go to dashboard
                      </button>
                    )}
                    {showloader == true ? (
                      <Lottie
                        animationData={Loader}
                        loop={true}
                        style={{
                          width: 50,
                          height: 50,
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal {...selfieProps}>
        <div className="col-xl-12 col-xxl-12">
          <Webcam
            ref={webcam}
            audio={false}
            height={450}
            screenshotFormat="image/png"
            width="100%"
            videoConstraints={{
              width: 1280,
              height: 720,
              facingMode: "user",
            }}
            style={{
              borderRadius: 10,
            }}
          />
        </div>

        {/* <div className="col-xl-12 col-xxl-12">
              <button
                className="btn btn-primary"
                onClick={async () => {
                  const imageSrc = webcam.current.getScreenshot({
                    width: 1920,
                    height: 1080,
                  });

                  if (imageSrc) {
                    // const blob = await dataToBlob(imageSrc);

                    // // then create URL object
                    // const url = URL.createObjectURL(blob);
                    handleChangeFile("selfie", imageSrc);
                    handleFormErrors("selfie", "");
                  }

                  setShowWebCam(false);
                }}
              >
                Capture photo
              </button>
              <button
                className="btn btn-danger ml-1"
                onClick={() => {
                  setShowWebCam(false);
                }}
              >
                Cancel
              </button>
            </div> */}
      </Modal>
    </Fragment>
  );
};

const formWizard = reduxForm({
  form: "submitAccount", // a unique identifier for this form
})(Wizard);

// Decorate with connect to read form values
// const selector = formValueSelector("submitAccount"); // <-- same as form name
const submitFormWizard = connect((state) => {
  return { state };
})(formWizard);

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(submitFormWizard);
